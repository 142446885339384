.img1 { grid-area: img1; }
.img2 { grid-area: img2; }
.img3 { grid-area: img3; }
.img4 { grid-area: img4; }
.img5 { grid-area: img5; }
.img6 { grid-area: img6; }
.img7 { grid-area: img7; }
.img8 { grid-area: img8; }
.img9 { grid-area: img9; }
.img10 { grid-area: img10; }
.img11 { grid-area: img11; }
.empty1 { grid-area: empty1; }
.empty2 { grid-area: empty2; }
.photo { grid-area: photo; }

.gallery-section-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
  'img1 img1 photo photo'
  'img1 img1 img2 img3'
  'img4 img4 img5 img6'
  'img4 img4 img7 img8'
  'img9 img10 img11 img11'
  'empty1 empty2 img11 img11';
  gap: 2rem;
}

.gallery-photo-text {
  display: flex;
  align-items: end;
  justify-content: center;
  text-align: center;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
}

.gallery-photo-text-wrapper {
  background-color: rgb(255, 255, 255);
  padding: 5px 50px;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.gallery-photo-text-wrapper h3 {
  font-size: 2rem;
  margin-bottom: 0;
  color: var(--bs-primary);
}

.gallery-section-col-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all .2s;
}

.home-photo-gallery-section-zoom-icon {
  position: absolute;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.home-photo-gallery-section-zoom-icon .icon {
  font-size: 1.5rem;
}

.home-photo-gallery-section-zoom-icon-show {
  display: flex;
  opacity: 1;
  animation: 0.3s zoom-show-animation linear;
  animation-fill-mode: both;
  cursor: pointer;
}

.home-photo-gallery-section-zoom-icon-show .icon {
  animation: 0.2s zoom-show-animation linear;
  animation-fill-mode: both;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.5rem;
}

@keyframes zoom-show-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gallery-section-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.gallery-section-col-wrapper:hover {
  transform: scale(1.03);
}

.photo-gallery-video-section {
  padding: 80px 0 180px 0;
}

.photo-gallery-section-video {
  width: 100%;
  height: 100%;
}

.photo-gallery-section-video iframe {
  width: 100%;
  aspect-ratio: 9 / 16;
}

@media only screen and (max-width: 1200px) {
  .gallery-section-grid {
    gap: 1rem;
  }

  .gallery-photo-text-wrapper h3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .gallery-section-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content;
    grid-template-areas:
    'photo photo'
    'img1 img1'
    'img2 img3'
    'img5 img6'
    'img4 img4'
    'img7 img8'
    'img9 img10'
    'img11 img11';
  }
}